/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ol: "ol",
    li: "li",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Setting Up Virtual Environment pipenv in VScode Jupyter notebook instance"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Open a terminal instance in the project directory"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "pipenv shell")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "pipenv --venv"), " to get the path to the virtual environment"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "cmd + shift + p"), " to open the command palette"), "\n", React.createElement(_components.li, null, "Select ", React.createElement(_components.code, null, "Python: Select Interpreter")), "\n", React.createElement(_components.li, null, "Select the virtual environment that matches the path from step 3 or add it directly"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
